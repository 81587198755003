import { apps } from './apps';

export const environment = {
  environment: 'production',
  production: true,
  appDomain: 'umami-view.app',
  appUrl: 'https://umami-view.app',
  apiUrl: 'https://isa-api.umami-view.app',
  integrationsApiUrl: 'https://integrations-api.umami-view.app',
  workSchedulerApi: 'https://work-scheduler-api.umami-view.app',
  spaceUrl: 'https://isaprod.blob.core.windows.net',
  msAutosaveDebounceTime: 2000,
  googleMapsKey: 'AIzaSyD_0ZSVH1L0Dn80itPWkk-JLBW5he81auY',
  defaultTheme: 'light-blue',
  googleTagManagerKey: 'GTM-TKK928M',
  userBackKey: '29834|43651|WOjV9G1GukoQVl70cgggNrVhN',
  userBackPortalUrl: 'https://umamiview.ideas.userback.io/p/mvoKD3fU0d',
  intercomAppId: 'ruqr1mvm',
  supportPhoneNumber: '+49 9904 9689489',
  notificationsCenterUri: 'https://notifications-api.umami-view.app',
  keycloak: {
    url: 'https://umami-view.app/auth',
    realm: 'isa',
    clientId: 'isa',
    enableLogging: false,
  },
  agGridKey:
    'CompanyName=Umami Ware GmbH,LicensedGroup=ISA,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-038931,SupportServicesEnd=2_March_2024_[v2]_MTcwOTMzNzYwMDAwMA==b0f40c1f73f40107ce4667cb2895dcc3',
  apps: {
    isa: {
      ...apps.isa,
      host: 'https://umami-view.app',
    },
    glasses: {
      ...apps.glasses,
      host: 'https://glasses.umami-view.app',
    },
    glassesV2: {
      ...apps.glassesV2,
      host: 'https://glasses-v2.umami-view.app',
      apiUrl: 'https://glasses-v2-api.umami-view.app',
    },
    visionProfile: {
      ...apps.visionProfile,
      host: 'https://vision-profile.umami-view.app',
    },
    caseHistory: {
      ...apps.caseHistory,
      host: 'https://case-history.umami-view.app',
    },
    measures: {
      ...apps.measures,
      actionButtonComponent: 'CreateActionButtonComponent',
      embeddedActionIcon: undefined,
      embeddedActionParam: undefined,
      host: 'https://measures.umami-view.app',
    },
    integrations: {
      ...apps.integrations,
      host: 'https://integrations.umami-view.app',
    },
    calendar: {
      ...apps.calendar,
      host: 'https://calendar.umami-view.app',
    },
    workspace: {
      ...apps.workspace,
      host: 'https://workspace.umami-view.app',
    },
    times: {
      ...apps.times,
      host: 'https://times.umami-view.app',
    },
    messages: {
      ...apps.messages,
      host: 'https://messages.umami-view.app',
    },
    marketing: {
      ...apps.marketing,
      host: null,
      route: null,
    },
    payments: {
      ...apps.payments,
      host: 'https://payments.umami-view.app',
    },
    analytics: {
      ...apps.analytics,
      host: 'https://analytics.umami-view.app',
    },
    products: {
      ...apps.products,
      actionButtonComponent: 'CreateActionButtonComponent',
      host: 'https://products.umami-view.app',
    },
    migrate: {
      ...apps.migrate,
      host: 'https://migrate.umami-view.app',
    },
    catalogs: {
      ...apps.catalogs,
      host: 'https://catalogs.umami-view.app',
    },
    businessActivity: {
      ...apps.businessActivity,
      host: 'https://business-activity.umami-view.app',
    },
  },
  teamviewerApiUrl: 'https://webapi.teamviewer.com/api/v1/sessions',
  teamviewerApiKey: '24345510-28Ms20ZnETGIUsweJ66R',
};
